import React from 'react';
import styled from 'styled-components';
import {Modal, Button, Input} from 'antd';
import {useOutlet} from 'reconnect.js';
import {Refresh} from '@styled-icons/material';
import ThemeContext from '../Theme';
import {StoreCreation, StoreOperation} from './StoreModal.comp';

function StoreModal(props) {
  const {instance, visible, setVisible, refreshStoreList} = props;
  const [current, setCurrent] = React.useState(0);
  const [name, setName] = React.useState('');
  const [actions] = useOutlet('actions');
  const [inputCurrent, setInputCurrent] = React.useState(0);
  const theme = React.useContext(ThemeContext);

  React.useEffect(() => {
    if (instance) {
      setCurrent(instance.current);
      setInputCurrent(instance.current);
    } else {
      setName('');
    }
  }, [instance]);

  async function closeModalAndRefresh() {
    setVisible(false);
    refreshStoreList();
  }

  const updateCurrent = async () => {
    actions.setLoading(true);
    let c = (await actions.fetchStoreById(instance.id)).current;
    actions.setLoading(false);
    setCurrent(c);
    setInputCurrent(c);
    return c;
  };

  const onAddCurrent = (delta) => async () => {
    if (current + delta < 0) {
      return;
    }

    try {
      actions.setLoading(true);
      const resp = await actions.addStoreCurrent(
        {store: instance.id, delta},
        current,
      );
      setCurrent(resp.current);
      setInputCurrent(resp.current);
    } catch (ex) {
      console.warn(ex);
      alert('API fail ' + JSON.stringify(ex, null, 2));
    } finally {
      actions.setLoading(false);
    }
  };

  const updateAndAddCurrent = async (delta) => {
    try {
      actions.setLoading(true);
      let c = (await actions.fetchStoreById(instance.id)).current;
      let resp = null;
      if (c + delta >= 0) {
        resp = await actions.addStoreCurrent({store: instance.id, delta}, c);
      }
      actions.setLoading(false);
      if (resp) {
        setCurrent(resp.current);
        setInputCurrent(resp.current);
      }
    } catch (e) {}
  };

  const onSetCurrent = async () => {
    try {
      actions.setLoading(true);
      const resp = await actions.setStoreCurrent({
        store: instance.id,
        current: parseInt(inputCurrent, 10),
      });
      setCurrent(resp.current);
      setInputCurrent(resp.current);
    } catch (ex) {
      console.warn(ex);
      alert('API fail ' + JSON.stringify(ex, null, 2));
    } finally {
      actions.setLoading(false);
    }
  };

  let compProps = {
    name,
    setName,
    inputCurrent,
    current,
    instance,
    updateCurrent,
    updateAndAddCurrent,
    setInputCurrent,
    onSetCurrent,
    closeModalAndRefresh,
  };

  let modalTitle = null;
  if (instance) {
    modalTitle = `[ ${instance.id.substring(0, 4)} ${instance.id.substring(
      4,
      8,
    )} ] ${instance.name}`;
  } else {
    modalTitle = '商店設定';
  }

  return (
    <Modal
      title={modalTitle}
      footer={null}
      width={500}
      visible={visible}
      closable={true}
      footer={null}
      style={{backgroundColor: '#F8FFF9', borderRadius: 20}}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}>
      <Wrapper>
        {/*!instance && <StoreCreation {...compProps} key="StoreCreation" />*/}

        {!instance && <StoreCreation {...compProps} key="StoreCreation" />}
        {instance && <StoreOperation {...compProps} key="StoreOperation" />}
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8fff9;
  border-radius: 20px;
`;

export default StoreModal;
