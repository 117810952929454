import React from 'react';
import styled from 'styled-components';
import {Button, Input, Upload} from 'antd';
import {useOutlet} from 'reconnect.js';
import {Refresh} from '@styled-icons/material';
import {UpArrow, DownArrow} from '@styled-icons/boxicons-regular';
import ThemeContext from '../Theme';
import Logo from '../images/LogoLight.png';

function StoreOperation({
  instance,
  updateCurrent,
  updateAndAddCurrent,
  setInputCurrent,
  onSetCurrent,
  current,
  inputCurrent,
}) {
  if (!instance.isActivated) {
    return (
      <ColCenter>
        <img src={Logo} style={{width: '60%', marginBottom: 20}} />

        <h2>審核中</h2>
        <h3>
          請聯繫
          <a
            href="https://www.facebook.com/freestay.cloud"
            target="_blank"
            rel="noreferrer">
            freestay.cloud
          </a>
          進行審核
        </h3>
      </ColCenter>
    );
  }

  return (
    <ColCenter>
      <div style={{color: 'grey', alignSelf: 'flex-start'}}>
        <a
          href={`/store?sid=${instance.id}`}
          target="_blank"
          style={{color: '#264653'}}
          rel="noreferrer">
          {(window ? window.location.origin : '') + '/store?sid=' + instance.id}
        </a>
      </div>

      <QRCodeArea
        url={
          (window ? window.location.origin : '') + '/store?sid=' + instance.id
        }
      />

      <ButtonRow css="margin-top:30px;width:100%;">
        <Button
          style={{
            width: 80,
            height: 80,
            borderRadius: 10,
            fontSize: 35,
            color: 'red',
          }}
          onClick={() => updateAndAddCurrent(-1)}>
          -1
        </Button>
        <Button
          style={{
            width: 80,
            height: 80,
            borderRadius: 10,
            fontSize: 35,
            color: 'green',
          }}
          onClick={() => updateAndAddCurrent(1)}>
          +1
        </Button>
      </ButtonRow>

      <NumPad
        css="margin-top:30px;"
        bgColor={'#EEEEEE'}
        textColor={'black'}
        onClick={updateCurrent}>
        {current}
        <Refresh
          style={{
            width: 25,
            height: 25,
            position: 'absolute',
            top: 10,
            right: 10,
          }}
          color="orange"
        />
      </NumPad>

      <Input
        value={inputCurrent}
        onChange={(e) => setInputCurrent(e.target.value)}
        type="number"
        style={{marginTop: 30, color: 'blue'}}
      />
      <ButtonRow css="margin-top:30px;width:100%;">
        <Button
          style={{
            width: 100,
            height: 50,
            borderRadius: 10,
            fontSize: 18,
            color: 'blue',
          }}
          onClick={onSetCurrent}>
          直接設定
        </Button>
        <Button
          style={{
            width: 140,
            height: 50,
            borderRadius: 10,
            fontSize: 14,
          }}
          onClick={() => updateCurrent()}>
          取消(回目前號碼)
        </Button>
      </ButtonRow>
    </ColCenter>
  );
}

function QRCodeArea({url}) {
  const [close, setClose] = React.useState(true);

  if (close) {
    return (
      <div
        style={{textAlign: 'center', curso: 'point', color: '#7f5539'}}
        onClick={() => setClose(false)}>
        <p>顯示 QR Code</p>
        <DownArrow style={{width: 25, height: 25}} />
      </div>
    );
  } else {
    return (
      <div
        style={{textAlign: 'center', curso: 'point', color: '#7f5539'}}
        onClick={() => setClose(true)}>
        <p>隱藏 QR Code</p>
        <p>
          <img
            src={
              'http://api.qrserver.com/v1/create-qr-code/?data=' +
              encodeURIComponent(url) +
              '&size=150x150'
            }
            width="150"
            height="150"
          />
        </p>
        <UpArrow style={{width: 25, height: 25}} />
      </div>
    );
  }
}

function StoreCreation({closeModalAndRefresh}) {
  const [actions] = useOutlet('actions');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [errorMsg, setErrorMag] = React.useState(null);

  const [imgUploadUrl, setImgUploadUrl] = React.useState(null);
  const [fileProcessing, setFileProcessing] = React.useState(false);

  const [storeJoinCode, setStoreJoinCode] = React.useState(null);

  const [actionType, setActionType] = React.useState('join'); // join or create

  const doJoin = async () => {
    setErrorMag(null);

    do {
      if (!storeJoinCode) {
        break;
      }

      actions.setLoading(true);
      try {
        await actions.joinStore(storeJoinCode);
        closeModalAndRefresh();
        actions.setLoading(false);
      } catch (e) {
        setErrorMag('邀請碼錯誤');
        actions.setLoading(false);
      }
    } while (0);

    setErrorMag('');
    actions.setLoading(false);
  };

  const doSubmit = async () => {
    setErrorMag(null);
    do {
      if (name === '' || name.length > 50) {
        setErrorMag('請檢查店名格式');
        break;
      }

      if (!/^\d+$/.test(phone) || phone.length !== 10) {
        setErrorMag('請檢查電話格式');
        break;
      }

      if (address === '') {
        setErrorMag('請檢查地址');
        break;
      }

      if (!imgUploadUrl) {
        setErrorMag('請上傳正確的圖片');
        break;
      }

      actions.setLoading(true);
      await actions.createStore(name, imgUploadUrl.expected, address, phone);
      closeModalAndRefresh();
      actions.setLoading(false);
    } while (0);
  };

  if (actionType === 'join') {
    return (
      <ColCenter>
        <img src={Logo} style={{width: '60%'}} />

        <InputRow
          css={
            'width: 100%;justify-content:space-around;margin-top:10px;background-color:#EEEEEE;border-radius:15px;padding:15px;'
          }>
          <div
            style={{
              fontSize: 25,
              color: actionType === 'create' ? '#7EC2B6' : 'grey',
              cursor: 'pointer',
            }}
            onClick={() => {
              setActionType('create');
              setErrorMag(null);
            }}>
            開新店家
          </div>
          <div
            style={{
              fontSize: 25,
              color: actionType === 'join' ? '#7EC2B6' : 'grey',
              cursor: 'pointer',
            }}
            onClick={() => {
              setActionType('join');
              setErrorMag(null);
            }}>
            加入店家
          </div>
        </InputRow>

        <InputCol css="margin-top:20px;width:80%;">
          <div style={{flex: 1}}>通行碼</div>
          <Input
            style={{marginTop: 5}}
            placeholder="請輸入一次性通行碼"
            type="number"
            onChange={(e) => {
              setStoreJoinCode(e.target.value);
              setErrorMag(null);
            }}
          />
        </InputCol>

        {errorMsg && <h3 style={{marginTop: 40, color: 'red'}}>{errorMsg}</h3>}

        <Button style={{marginTop: 40}} onClick={doJoin}>
          提交
        </Button>
      </ColCenter>
    );
  }

  return (
    <ColCenter>
      <img src={Logo} style={{width: '60%'}} />

      <InputRow
        css={
          'width: 100%;justify-content:space-around;margin-top:10px;background-color:#EEEEEE;border-radius:15px;padding:15px;'
        }>
        <div
          style={{
            fontSize: 25,
            color: actionType === 'create' ? '#7EC2B6' : 'grey',
            cursor: 'pointer',
          }}
          onClick={() => {
            setActionType('create');
            setErrorMag(null);
          }}>
          開新店家
        </div>
        <div
          style={{
            fontSize: 25,
            color: actionType === 'join' ? '#7EC2B6' : 'grey',
            cursor: 'pointer',
          }}
          onClick={() => {
            setActionType('join');
            setErrorMag(null);
          }}>
          加入店家
        </div>
      </InputRow>

      <InputCol css="margin-top:10px;width:80%;">
        <div style={{flex: 1}}>名稱</div>
        <Input
          style={{marginTop: 5}}
          placeholder="長度請短於 50 個字"
          onChange={(e) => {
            setName(e.target.value);
            setErrorMag(null);
          }}
        />
      </InputCol>

      <InputCol css="margin-top:10px;width:80%;">
        <div style={{flex: 1}}>電話</div>
        <Input
          style={{marginTop: 5}}
          placeholder="請輸入您的手機或市話 (以 0 開頭)"
          onChange={(e) => {
            setPhone(e.target.value);
            setErrorMag(null);
          }}
          type="number"
        />
      </InputCol>

      <InputCol css="margin-top:10px;width:80%;">
        <div style={{flex: 1}}>地址</div>
        <Input.TextArea
          style={{marginTop: 5}}
          placeholder="請輸入您的店家地址"
          onChange={(e) => {
            setAddress(e.target.value);
            setErrorMag(null);
          }}
        />
      </InputCol>

      <InputCol css="margin-top:10px;width:80%;">
        <div style={{flex: 1}}>圖片</div>
        <Upload
          action={imgUploadUrl && imgUploadUrl.url}
          data={imgUploadUrl && imgUploadUrl.fields}
          listType="picture"
          onChange={({file}) => {
            if (file.status === 'error') {
              setErrorMag('檔案錯誤');
            }
          }}
          beforeUpload={async (f) => {
            if (f.size > 2 * 1024 * 1024) {
              setErrorMag('檔案過大');
              return Promise.reject();
            }

            try {
              let url = await actions.getImgUploadUrl(
                f.type.toLowerCase(),
                f.name,
              );
              setImgUploadUrl(url);
            } catch (err) {
              setErrorMag('發生不明錯誤 ... 請重新上傳');
              setImgUploadUrl(null);
              return Promise.reject();
            }
          }}
          maxCount={1}>
          <Button
            onClick={() => {
              setErrorMag(null);
            }}>
            請選取 2MB 內之檔案
          </Button>
        </Upload>
      </InputCol>

      {errorMsg && <h3 style={{marginTop: 40, color: 'red'}}>{errorMsg}</h3>}

      <Button
        style={{marginTop: 40}}
        onClick={doSubmit}
        disabled={fileProcessing}>
        提交審核
      </Button>
    </ColCenter>
  );
}

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.css || ''};
`;

const InputCol = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: flex-start;
  justify-content: flex-start;
  ${(props) => props.css || ''};
`;

const ColCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NumPad = styled.div`
  width: 20%;
  height: 20%;
  min-width: 150px;
  min-height: 150px;

  border-radius: 20px;
  border-width: 2px;
  border-color: black;

  background-color: ${(props) => props.bgColor};

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 50px;
  font-weight: 500;
  color: ${(props) => props.textColor};

  position: relative;

  ${(props) => props.css || ''};
`;

const Center = styled.div`
  max-width: 320px;
  margin: 0 auto;
`;

const FieldRow = styled.div`
  margin-bottom: 10px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  ${(props) => props.css || ''};
`;

export {StoreCreation, StoreOperation};
