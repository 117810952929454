import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as Generic from '../../Generic';
import StoreModal from '../../Components/StoreModal';
import {navigate} from 'gatsby';

const Settings = require('../../../data.json');

function Dashboard(props) {
  const [actions] = useOutlet('actions');
  const [forceUpdater, setForceUpdater] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [instance, setInstance] = React.useState(null);

  // Test whether needing re-login
  React.useEffect(() => {
    actions.fetchStores().catch((e) => {
      if (JSON.stringify(e).indexOf('403') !== -1) {
        actions.logout();
        navigate('/');
      }
    });
  }, []);

  return (
    <>
      <Generic.Resource
        spec={{
          path: '/admin/stores',
          name: '店家',
          primaryKey: 'id',
          actions: {
            setLoading: actions.setLoading,
            fetchRecords: actions.fetchStores,
            fetchRecordById: actions.fetchStoreById,
          },
          maxCreateNum: Settings.env === 'stg' ? 100 : 1,
          columns: [
            {
              title: '編號',
              key: 'id',
              dataIndex: 'id',
            },

            {
              title: '名稱',
              key: 'name',
              dataIndex: 'name',
            },
          ],
        }}
        onCreate={() => {
          setInstance(null);
          setVisible(true);
        }}
        onGoToDetail={async (record) => {
          try {
            actions.setLoading(true);
            setInstance(await actions.fetchStoreById(record.id));
            setVisible(true);
          } catch (ex) {
            alert('API Fail ' + JSON.stringify(ex, null, 2));
          } finally {
            actions.setLoading(false);
          }
        }}
        renderDetail={() => null}
        generateJoinCode={(storeId) => {
          actions
            .generateJoinCode(storeId)
            .then((code) => alert(JSON.stringify(code)));
        }}
        forceUpdater={forceUpdater}
        {...props}
      />

      <div
        style={{
          backgroundColor: 'white',
          paddingTop: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <NotePad />
      </div>

      <StoreModal
        instance={instance}
        visible={visible}
        setVisible={setVisible}
        refreshStoreList={() => setForceUpdater(forceUpdater + 1)}
      />
    </>
  );
}

function NotePad() {
  return (
    <div style={{padding: 15, borderRadius: 15, backgroundColor: '#d8e2dc'}}>
      <p>1. 請勿於無痕視窗內使用</p>
      <p>2. 若遇到操作問題請「先登出再登入」再「重新整理」</p>
      <p>
        3. 其他問題請聯繫客服 (fb:{' '}
        <a
          href="https://www.facebook.com/freestay.cloud"
          target="_blank"
          rel="noreferrer">
          freeStay.cloud
        </a>
        )
      </p>
    </div>
  );
}

export default Dashboard;
